// Variables
@import "../../../sass/variables";

.store-info {
  dl {
    margin: 0;
    display: flex;

    dt, dd {
      margin: 0;
    }

    dt {
      width: 60px;
      //text-align: right;
    }

    dd {
      width: 450px;
    }
  }
}

.store-photo {
  width: 40%;
  margin-top: 2rem;
}

.calender-content {
  width: 100%;
  max-width: 300px;
  margin-top: 3rem;

  .square {
    display: inline-block;
    vertical-align: middle;
    background-color: #ff104e;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .calender-area {
    border: solid 1px $theme-main-gray;
    text-align: center;

    .calendar-0, .calendar-2 {
      display: none;
    }

    h5 {
      margin: 0.5rem auto;

      .prev-btn, .next-btn {
        cursor: pointer;
      }
    }

    .calender-store {
      width: 100%;

      th {
        background-color: $theme-main-lightgray;
      }
    }
  }

}

@media (max-width: 767px) {
  .store-info {
    dl {
      dt {
        width: 60px;
      }

      dd {
        width: 235px;
      }
    }
  }

  .store-photo {
    width: 75%;
    margin-top: 1rem;
  }
}
